import React, { useState, useEffect, useMemo } from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionDestinationList } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button, Icons } from '../../../atoms'
import { IntegrationCard } from '../../resources/integrations'
import { useLocale } from '../../../hooks/useLocale'
import { destinationsFrQuery } from './DestinationsFr.query'
import { destinationsEnQuery } from './DestinationsEn.query'

const DestinationList: React.FC<SectionDestinationList> = (props) => {
  const { id, sectionId, headline, searchInput, searchInputPlaceholder, emptyStateLabel, showBy, buttons } = props
  const { locale } = useLocale()

  const destinations = useMemo(() => {
    if (locale === 'fr') {
      return destinationsFrQuery()
    } else {
      return destinationsEnQuery()
    }
  }, [locale])

  const [search, setSearch] = useState('')
  const [filteredDestinations, setFilteredDestinations] = useState(destinations.destinations.nodes)

  useEffect(() => {
    if (search !== '') {
      const df = destinations.destinations.nodes.filter((d) =>
        d.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      setFilteredDestinations(df)
    } else {
      const df = destinations.destinations.nodes.slice(0, showBy)
      setFilteredDestinations(df)
    }
  }, [search, showBy])

  return (
    <Box as={'section'} id={sectionId || id}>
      <Box my={SECTION_MARGIN}>
        <Grid>
          <Box>
            <Row center={'xs'}>
              <Col xs={12} md={10} lg={8}>
                <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
                  {headline}
                </Heading>
              </Col>
            </Row>
          </Box>

          {searchInput && (
            <Box mt={7}>
              <Row center={'xs'}>
                <Col xs={12} md={6} lg={4}>
                  <Box position={'relative'}>
                    <Box position={'absolute'} left={'12px'} top={'50%'} style={{ transform: 'translateY(-50%)' }}>
                      <Icons.Search />
                    </Box>
                    <input
                      type='text'
                      placeholder={searchInputPlaceholder}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '13px 16px 13px 40px',
                      }}
                    />
                  </Box>
                </Col>
              </Row>
            </Box>
          )}

          <Box mt={[8, 10, 80]} mb={[6, 8, 10]}>
            <Row>
              <Col xs={12}>
                {filteredDestinations.length > 0 ? (
                  <Row center={'xs'} style={{ rowGap: '32px' }}>
                    {filteredDestinations.map((d) => (
                      <Col key={d.id} xs={12} md={3}>
                        <IntegrationCard {...d} />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Box my={8}>
                    <Text fontWeight={'semi'} color={'grey50'} textAlign={'center'}>
                      {emptyStateLabel}
                    </Text>
                  </Box>
                )}
              </Col>
            </Row>
          </Box>

          {buttons.length > 0 && (
            <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
              {buttons.map((b, idx) => (
                <Button key={b.id} {...b} my={2} mr={idx + 1 < buttons.length ? [4, 4, 5] : undefined} />
              ))}
            </Flex>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default DestinationList
