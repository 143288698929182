import { graphql, useStaticQuery } from 'gatsby'
import { IIntegrationCard } from '../../../types/resources'

interface DestinationsQuery {
  destinations: {
    nodes: IIntegrationCard[]
  }
}

export const destinationsEnQuery = (): DestinationsQuery => {
  return useStaticQuery<DestinationsQuery>(graphql`
    query {
      destinations: allDatoCmsIntegration(locale: "en", sort: { fields: position, order: ASC }) {
        nodes {
          ...integrationCardFragment
        }
      }
    }
  `)
}
